import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    isOnline: true,
    offlineTimer: 0,
    maxTimeOffline: 5 * 60,
    user: null,
  },
  reducers: {
    setIsOnline: (state, action) => {
      state.isOnline = action.payload;
    },
    setOfflineTimer: (state, action) => {
      state.offlineTimer = action.payload;
    },
    resetOfflineTimer: (state) => {
      state.offlineTimer = 0;
    },
    increaseOfflineTimer: (state) => {
      state.offlineTimer += 1;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
    },
  },
});

export const {
  setUser,
  logoutUser,
  setIsOnline,
  setOfflineTimer,
  resetOfflineTimer,
  increaseOfflineTimer,
} = globalSlice.actions;

export default globalSlice.reducer;
