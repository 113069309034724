const QUESTION_TYPE_MAP = {
  numerical: "Numerical",
  singleCorrect: "Single Correct",
  multipleCorrect: "Multiple Correct",
  fillTheBlanks: "Fill the Blanks",
};

const ANALYSIS_TABS = [
  {
    name: "Performance",
    value: "performance",
    link: "performance",
  },
  {
    name: "Timeline",
    value: "overview",
    link: "timeline",
  },
  {
    name: "Qs Type Breakup",
    value: "time-accuracy",
    link: "qs-type-breakup",
  },
  {
    name: "Quality of Attempt",
    value: "quality",
    link: "quality-of-attempt",
  },
  {
    name: "Quality of Time Spent",
    value: "difficulty",
    link: "quality-of-time-spent",
  },
  {
    name: "Subject-Wise Time",
    value: "subject-wise-time",
    link: "subject-wise-time",
  },
  {
    name: "Hour-wise Breakdown",
    value: "hour-wise-breakdown",
    link: "hour-wise-breakdown",
  },
  // {
  //   name: "Last one hour",
  //   value: "errors",
  //   link: "last-one-hour",
  // },
  {
    name: "Difficulty Analysis",
    value: "subject-movement",
    link: "difficulty-analysis",
  },
  {
    name: "Unit Analysis",
    value: "all-questions",
    link: "unit-analysis",
  },
  {
    name: "Chapter & Topic Analysis",
    value: "question-journey",
    link: "chapter-topic-analysis",
  },
];
const TEST_ANALYSIS_TABS = [
  {
    name: "Overview",
    value: "overview",
    link: "overview",
  },
  {
    name: "Detailed Analysis",
    value: "detailed",
    link: "detailed-analysis",
  },
  {
    name: "Time & Accuracy",
    value: "time-accuracy",
    link: "time-accuracy",
  },
  {
    name: "Quality of Attempts",
    value: "quality",
    link: "quality-of-attempt",
  },
  {
    name: "Difficulty Analysis",
    value: "difficulty",
    link: "difficulty-analysis",
  },
  {
    name: "Errors",
    value: "errors",
    link: "errors",
  },
  {
    name: "Subject Movement",
    value: "subject-movement",
    link: "subject-movement",
  },
  {
    name: "Question Journey",
    value: "question-journey",
    link: "question-journey",
  },
  {
    name: "All Questions",
    value: "all-questions",
    link: "all-questions",
  },
];

const QUESTION_DIFFICULTY = {
  1: "Easy",
  2: "Medium",
  3: "Hard",
};

const ATTEMPT_OVERVIEW = {
  perfect: {
    title: "Perfect",
    className: "success",
  },
  wasted: {
    title: "Wasted",
    className: "danger",
  },
  overtime: {
    title: "Overtime",
    className: "warning",
  },
  confused: {
    title: "Confused",
    className: "dark",
  },
};

export {
  ANALYSIS_TABS,
  QUESTION_DIFFICULTY,
  ATTEMPT_OVERVIEW,
  TEST_ANALYSIS_TABS,
};

export default QUESTION_TYPE_MAP;