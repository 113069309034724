import { createSlice } from "@reduxjs/toolkit";

export const timerSlice = createSlice({
  name: "timer",
  initialState: {
    sectionTimers: {},
    sections: [],
  },
  reducers: {
    addSection: (state, action) => {
      const { section } = action.payload;
      if (state.sections[state.sections.length - 1] === section) return;
      state.sections = [...state.sections, section];
      state.sectionTimers = {
        ...state.sectionTimers,
        [section]: 0,
      };
    },
    startSectionTimer: (state, action) => {
      const { currentSection } = action.payload;
      if (!state.sectionTimers[currentSection]) {
        state.sectionTimers[currentSection] = 1;
      } else {
        state.sectionTimers[currentSection] =
          state.sectionTimers[currentSection] + 1;
      }
    },

    resetSectionTimer: (state, action) => {
      const { currentSection } = action.payload;
      state.sectionTimers[currentSection] = 0;
    },

    clearTimers: (state) => {
      state.sectionTimers = {};
    },
  },
});

export const { startSectionTimer, resetSectionTimer, clearTimers, addSection } =
  timerSlice.actions;

export default timerSlice.reducer;
