import React, { useContext, useEffect, useState } from 'react';
import Sidenav from '../components/sidenav';
import ThemeSwitch from '../components/global/ThemeSwitch';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import BottomNav from '../components/global/BottomNav';
import ChangeTheme from '../components/global/ChangeTheme';
import { ThemeContext } from '../context/ThemeStore';
import UserAuthProfile from '../components/global/UserAuthProfile';

const Dashboard = ({ children }) => {
  const [cookies] = useCookies();
  const router = useHistory();
  const [isTestPage, setIsTestPage] = useState(false);

  const { user } = useContext(ThemeContext);
  const location = useLocation();

  const isLms = sessionStorage.getItem("lms");

  useEffect(() => {
    if (!Cookies.get('token') && !sessionStorage.getItem("token")) {
      router.push(`/auth?redirect_url=${window.location.pathname + window.location.search}`);
    }
    const user = JSON.parse(localStorage.getItem('userProfile'));
    if (user && !user.isOnboardingDone && !isLms) router.push(`/onboarding?step=1`);
  }, []);


  useEffect(() => {
    // Function to check if the current path is a test page
    const checkIfTestPage = () => {
      const testPageRegex = /^\/[a-f0-9]{24}\/[a-f0-9]{24}$/;
      return testPageRegex.test(location.pathname);
    };

    setIsTestPage(checkIfTestPage());
  }, [location]);


  return (
    <div>
      <UserAuthProfile />
      {!isLms  &&  <Sidenav />}
      <ChangeTheme isLms={isLms} />
      <div className="main-content mb-5 mb-sm-0 pb-4 pb-sm-0">
        {children}
        </div>
      {!isLms && <BottomNav />}
    </div>
  );
};

export default Dashboard;
